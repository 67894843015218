<template>
  <div>
    <vue-element-loading
    :active="appLoading"
      spinner="bar-fade-scale"
      color="#4480C5"
      size="60"
      is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-3>
      <v-flex xs12>
        <!-- Card with Expandable Section or Add Form -->
        <v-card>
          <v-card-title>
            <v-flex xs11 sm11 md4 lg3>
              <span class="holymasshdes">
                {{
                  isAddingcon
                    ? currentAddType === "normal"
                      ? "Add Confession"
                      : currentAddType === "special"
                      ? "Add Special Confession"
                      : "Add Monthly Confession"
                    : "Confession Timing"
                }}
              </span>
            </v-flex>
            <v-flex xs1 class="hidden-md-and-up">
              <v-btn v-if="!isAddingcon" icon @click="toggleExpand">
                <v-icon>{{
                  expandedconf ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-flex>
            <v-layout wrap justify-end>
              <!-- Button container with responsive design -->
              <v-flex xs12 sm12 md12 lg10 pl-lg-5 pl-sm-5 pl-md-5 pl-0 justify-end>
                <v-layout wrap justify-end>
                  <!-- Add Holy Mass Button -->
                  <v-flex pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn
                      small
                      v-if="!isAddingcon"
                      color="blue"
                      style="color: white"
                      @click="startAdd('normal')"
                    >
                      Add Confession
                    </v-btn>
                  </v-flex>

                  <!-- Add Special Mass Button -->
                  <v-flex pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn
                      small
                      v-if="!isAddingcon"
                      color="blue"
                      style="color: white"
                      @click="startAdd('special')"
                    >
                      Add Special Confession
                    </v-btn>
                  </v-flex>

                  <!-- Add Monthly Mass Button -->
                  <v-flex pb-2 pb-lg-0 pb-sm-0 pb-md-0 pb-xl-0>
                    <v-btn
                      small
                      v-if="!isAddingcon"
                      color="blue"
                      style="color: white"
                      @click="startAdd('monthly')"
                    >
                      Add Monthly Confession
                    </v-btn>
                  </v-flex>

                  <!-- Cancel Button -->

                  <!-- Expand/Collapse Button -->
                  <v-flex xs1 pl-lg-6 pl-md-6 class="hidden-sm-and-down">
                    <v-btn v-if="!isAddingcon" icon @click="toggleExpand">
                      <v-icon>{{
                        expandedconf ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon>
                    </v-btn>
                  </v-flex>

                  <v-flex xs10 text-end my-1>
                    <v-btn
                      v-if="isAddingcon"
                      color="red"
                      icon
                      @click="toggleAddMode"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-title>

          <v-expand-transition>
            <v-card-text v-if="expandedconf && !isAddingcon">
              <v-flex>
                <v-card outlined elevation="0" class="pa-4 pt-4">
                  <v-layout wrap justify-start>
                    <v-flex
                      xs12
                      mb-3
                      style="
                        font-family: intersemibold;
                        color: black;
                        font-size: 17px;
                      "
                    >
                      Normal Confession
                    </v-flex>
                    <v-layout wrap justify-start pl-3>
                      <v-flex
                      xs4
                      sm2
                      md1
                      lg1
                        pb-3
                        v-for="day in daysOfWeek"
                        :key="day.value"
                      >
                        <v-btn
                          class="pl-3"
                          elevation="0"
                          :class="{
                            'weekday-btn': true,
                            'selected-btn': selectedDay === day.value,
                          }"
                          @click="handleDayClick(day.value)"
                        >
                          {{ day.name }}
                        </v-btn>
                      </v-flex>

                      <!-- Conditional rendering based on holyMassEntries array -->

                      <v-flex
                        xs12
                        style="
                          font-family: intersemibold;
                          color: #0975cc;
                          font-size: 15px;
                        "
                      >
                        Confession Details
                      </v-flex>
                      <template v-if="normalconf.length > 0">
                        <v-flex
                          pt-2
                          pr-2
                          xs12
                          sm6
                          md4
                          lg4
                          v-for="conf in normalconf"
                          :key="conf._id"
                        >
                          <v-card
                            rounded="lg"
                            flat
                            color="#e5f5ff"
                            class="ma-2"
                          >
                            <v-card-text class="text-left">
                              <v-layout>
                                <v-flex
                                  xs11
                                  style="
                                    color: black;
                                    font-family: interregular;
                                  "
                                >
                                  <span
                                    style="
                                      color: black;
                                      font-family: interregular;
                                    "
                                  >
                                    <v-icon size="18px" color="primary"
                                      >mdi-clock</v-icon
                                    >
                                    {{ formatTime(conf.startTime) }} -
                                    {{ formatTime(conf.endTime) }}
                                  </span>
                                </v-flex>

                                <v-flex xs1 class="text-right">
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        size="20px"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="black"
                                        >mdi-dots-vertical</v-icon
                                      >
                                    </template>
                                    <v-list>
                                      <v-list-item @click="editSlider(conf)">
                                        <v-icon small color="primary"
                                          >mdi-pencil</v-icon
                                        >
                                        <v-list-item-content
                                          class="pl-3 addChurch"
                                          >Edit</v-list-item-content
                                        >
                                      </v-list-item>
                                      <v-list-item
                                        @click="openDeleteDialog(conf)"
                                      >
                                        <v-icon color="red" small
                                          >mdi-delete</v-icon
                                        >
                                        <v-list-item-content
                                          class="pl-3 addChurch"
                                          >Delete</v-list-item-content
                                        >
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
                              </v-layout>

                              <v-flex>
                                <span
                                  style="
                                    color: black;
                                    font-family: interregular;
                                  "
                                >
                                  {{ conf.rite.name }},
                                  {{ conf.language.languageName }}
                                </span>
                              </v-flex>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </template>

                      <!-- Show this if holyMassEntries array is empty -->
                      <template v-else>
                        <v-flex
                          xs12
                          text-center
                          py-5
                          style="
                            font-family: intersemibold;
                            color: grey;
                            font-size: 13px;
                          "
                        >
                          No Confessions Available
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex pt-5>
                <v-card outlined elevation="0" class="pa-4 pt-4">
                  <v-layout wrap justify-start>
                    <v-flex
                      xs12
                      mb-3
                      style="
                        font-family: intersemibold;
                        color: black;
                        font-size: 17px;
                      "
                    >
                      Special Day - Confession
                    </v-flex>
                    <v-layout wrap justify-start pl-3>
                      <!-- Conditional rendering based on holyMassEntries array -->

                      <v-flex
                        xs12
                        style="
                          font-family: intersemibold;
                          color: #0975cc;
                          font-size: 15px;
                        "
                      >
                        Confession Details
                      </v-flex>
                      <template v-if="specialconf.length > 0">
                        <v-flex
                          pt-2
                          pr-2
                          xs12
                          sm6
                          md4
                          lg4
                          v-for="conf in specialconf"
                          :key="conf._id"
                        >
                          <v-card
                            rounded="lg"
                            flat
                            color="#e5f5ff"
                            class="ma-2"
                          >
                            <v-card-text class="text-left">
                              <v-layout>
                                <v-flex
                                  xs11
                                  style="
                                    color: black;
                                    font-family: interregular;
                                    text-transform: capitalize;
                                  "
                                >
                                  <span>
                                    <v-icon size="17px" color="primary"
                                      >mdi-calendar</v-icon
                                    >
                                    {{ formatDate1(conf.occasionDate) }}
                                  </span>
                                </v-flex>
                                <v-flex xs1 class="text-right">
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        size="20px"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="black"
                                        >mdi-dots-vertical</v-icon
                                      >
                                    </template>
                                    <v-list>
                                      <v-list-item
                                        @click="editSliderSpecial(conf)"
                                      >
                                        <v-icon small color="primary"
                                          >mdi-pencil</v-icon
                                        >
                                        <v-list-item-content
                                          class="pl-3 addChurch"
                                          >Edit</v-list-item-content
                                        >
                                      </v-list-item>
                                      <v-list-item
                                        @click="openDeleteDialog(conf)"
                                      >
                                        <v-icon color="red" small
                                          >mdi-delete</v-icon
                                        >
                                        <v-list-item-content
                                          class="pl-3 addChurch"
                                          >Delete</v-list-item-content
                                        >
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
                              </v-layout>

                              <v-flex
                                style="color: black; font-family: interregular"
                              >
                                <span
                                  style="
                                    color: black;
                                    font-family: interregular;
                                  "
                                >
                                  <v-icon size="17px" color="primary"
                                    >mdi-clock</v-icon
                                  >
                                  {{ formatTime(conf.startTime) }} -
                                  {{ formatTime(conf.endTime) }}
                                </span>
                              </v-flex>

                              <v-flex>
                                <span
                                  style="
                                    color: black;
                                    font-family: interregular;
                                  "
                                >
                                  {{ conf.rite.name }},
                                  {{ conf.language.languageName }}
                                </span>
                              </v-flex>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                        <!-- edit mass -->
                        <v-dialog v-model="editspecialdialog" max-width="800px">
                          <v-card rounded="lg">
                            <v-card-title>
                              <span class="editbox"
                                >EDIT SPECIAL CONFESSION
                              </span>
                              <v-spacer></v-spacer>
                            </v-card-title>

                            <v-card-text>
                              <v-form>
                                <v-layout wrap justify-start px-2>
                                  <v-flex xs6 pr-2>
                                    <span class="label"> Rite </span>
                                    <v-select
                                      class="pt-2 text-des"
                                      style="font-size: 14px"
                                      outlined
                                      dense
                                      :items="riteArray"
                                      v-model="editingitem.rite"
                                      item-text="name"
                                      item-value="_id"
                                    ></v-select>
                                  </v-flex>
                                  <v-flex xs6 pr-2>
                                    <span class="label"> Language </span>
                                    <v-select
                                      class="pt-2 text-des"
                                      style="font-size: 14px"
                                      outlined
                                      dense
                                      :items="languageArray"
                                      item-text="languageName"
                                      item-value="_id"
                                      v-model="editingitem.language"
                                    ></v-select>
                                  </v-flex>

                                  <v-flex xs6 pr-2>
                                    <span class="label">Date</span>

                                    <v-menu
                                      ref="menu"
                                      class="text-des pr-2"
                                      style="font-size: 14px"
                                      v-model="menu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          class="pt-2 text-des pr-2"
                                          v-model="formattedDate"
                                          outlined
                                          dense
                                          hide-details
                                          v-bind="attrs"
                                          v-on="on"
                                          readonly
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="formattedDate"
                                         :min="nowDate"
                                        @input="updateDate"
                                        locale="en"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-flex>

                                  <v-flex xs6 pr-2>
                                    <span class="label">Start Time</span>
                                    <v-layout>
                                      <v-select
                                        outlined
                                        dense
                                        hide-details
                                        class="pt-2 text-des pr-2"
                                        :items="hoursArray"
                                        v-model="editingitem.startHours"
                                        placeholder="Hours"
                                      ></v-select>
                                      <v-select
                                        outlined
                                        dense
                                        hide-details
                                        class="pt-2 text-des pr-2"
                                        :items="minutesArray"
                                        v-model="editingitem.startMinutes"
                                        placeholder="Minutes"
                                      ></v-select>
                                      <v-select
                                        outlined
                                        dense
                                        hide-details
                                        class="pt-2 text-des pr-2"
                                        :items="periodArray"
                                        v-model="editingitem.startPeriod"
                                        placeholder="AM/PM"
                                      ></v-select>
                                    </v-layout>
                                  </v-flex>

                                  <v-flex xs6 pr-2 pt-3>
                                    <span class="label">End Time</span>
                                    <v-layout>
                                      <v-select
                                        outlined
                                        dense
                                        hide-details
                                        class="pt-2 text-des pr-2"
                                        :items="hoursArray"
                                        v-model="editingitem.endHours"
                                        placeholder="Hours"
                                      ></v-select>
                                      <v-select
                                        outlined
                                        dense
                                        hide-details
                                        class="pt-2 text-des pr-2"
                                        :items="minutesArray"
                                        v-model="editingitem.endMinutes"
                                        placeholder="Minutes"
                                      ></v-select>
                                      <v-select
                                        outlined
                                        dense
                                        hide-details
                                        class="pt-2 text-des pr-2"
                                        :items="periodArray"
                                        v-model="editingitem.endPeriod"
                                        placeholder="AM/PM"
                                      ></v-select>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-form>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions
                              class="py-4 justify-end headline grey lighten-2"
                            >
                              <v-btn
                                outlined
                                color="grey"
                                text
                                @click="editspecialdialog = false"
                                ><span style="color: black">Cancel</span>
                              </v-btn>
                              <v-btn
                                color="primary"
                                @click="editSpecial(editingitem._id)"
                                >Save</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>

                      <!-- Show this if holyMassEntries array is empty -->
                      <template v-else>
                        <v-flex
                          xs12
                          text-center
                          py-5
                          style="
                            font-family: intersemibold;
                            color: grey;
                            font-size: 13px;
                          "
                        >
                          No Confessions Available
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-layout>

                  <v-layout wrap justify-center pt-2 v-if="specialconf">
                    <v-flex xs12 v-if="specialconf.length > 0">
                     
                      <v-pagination
                      size="small"
                     
                      color="primary white--text"
                      v-model="page"
                      :length="Pagelength" circle
                    >
                    </v-pagination>
                      
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex pt-5>
                <v-card outlined elevation="0" class="pa-4 pt-4">
                  <v-layout wrap justify-start>
                    <v-flex
                      xs12
                      mb-3
                      style="
                        font-family: intersemibold;
                        color: black;
                        font-size: 17px;
                      "
                    >
                      Monthly Confession
                    </v-flex>
                    <v-layout wrap justify-start pl-3>
                      <!-- <v-flex
                        xs12
                        style="
                          font-family: intersemibold;
                          color: #0975cc;
                          font-size: 15px;
                        "
                      >
                        Select Week
                      </v-flex> -->
                      <!-- <v-flex xs6 sm3 md2 lg2 pt-3 v-for="day in numOfWeek" :key="day.value">
                    <v-btn small size="30px" :class="{
                      'weekday-btn': true,
                      'selected-btn': selectedWeek === day.value,
                    }" @click="handleWeekClick(day.value)">
                      {{ day.name }}
                    </v-btn>
                  </v-flex> -->

                      <v-flex
                        class="hidden-xs-only"
                        xs6
                        sm3
                        md2
                        lg2
                        pb-2
                        v-for="(day, index) in numOfWeek"
                        :key="day.value + '-' + index"
                      >
                        <v-btn
                          elevation="0"
                          size="30px"
                          :class="{
                            'weekday-btn': true,
                            'selected-btn': selectedWeek === day.value,
                          }"
                          @click="handleWeekClick(day.value)"
                        >
                          {{ day.name }}
                        </v-btn>
                      </v-flex>

                      <v-flex
                        class="hidden-sm-and-up"
                        xs6
                        sm3
                        md2
                        lg2
                        pt-2
                        v-for="day in numOfWeek"
                        :key="day.value"
                      >
                        <v-btn
                          elevation="1"
                          small
                          size="30px"
                          :class="{
                            'weekday-btn': true,
                            'selected-btn': selectedWeek === day.value,
                          }"
                          @click="handleWeekClick(day.value)"
                        >
                          {{ day.name }}
                        </v-btn>
                      </v-flex>

                      <!-- Conditional rendering based on holyMassEntries array -->

                      <v-flex
                        xs12
                        pt-3
                        style="
                          font-family: intersemibold;
                          color: #0975cc;
                          font-size: 15px;
                        "
                      >
                        Confession Details
                      </v-flex>
                      <template v-if="monthlyConf.length > 0">
                        <v-flex
                          pt-2
                          pr-2
                          xs12
                          sm6
                          md4
                          lg4
                          v-for="conf in monthlyConf"
                          :key="conf._id"
                        >
                          <v-card
                            rounded="lg"
                            flat
                            color="#e5f5ff"
                            class="ma-2"
                          >
                            <v-card-text class="text-left">
                              <v-layout>
                                <v-flex
                                  xs11
                                  style="
                                    color: black;
                                    font-family: interregular;
                                  "
                                >
                                  <span
                                    style="
                                      color: black;
                                      font-family: interregular;
                                    "
                                  >
                                    <v-icon size="17px" color="primary"
                                      >mdi-white-balance-sunny</v-icon
                                    >
                                    {{
                                      getDayName(
                                        conf.recurringMonthly.dayOfWeek
                                      )
                                    }}
                                  </span>
                                  <br />
                                  <span
                                    style="
                                      color: black;
                                      font-family: interregular;
                                    "
                                  >
                                    <v-icon size="17px" color="primary"
                                      >mdi-clock</v-icon
                                    >
                                    {{ formatTime(conf.startTime) }} -
                                    {{ formatTime(conf.endTime) }}
                                  </span>
                                </v-flex>
                                <v-flex xs1 class="text-right">
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        size="20px"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="black"
                                        >mdi-dots-vertical</v-icon
                                      >
                                    </template>
                                    <v-list>
                                      <v-list-item
                                        @click="editSliderMonthly(conf)"
                                      >
                                        <v-icon small color="primary"
                                          >mdi-pencil</v-icon
                                        >
                                        <v-list-item-content
                                          class="pl-3 addChurch"
                                          >Edit</v-list-item-content
                                        >
                                      </v-list-item>
                                      <v-list-item
                                        @click="openDeleteDialog(conf)"
                                      >
                                        <v-icon color="red" small
                                          >mdi-delete</v-icon
                                        >
                                        <v-list-item-content
                                          class="pl-3 addChurch"
                                          >Delete</v-list-item-content
                                        >
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-flex>
                              </v-layout>

                              <v-flex>
                                <span
                                  style="
                                    color: black;
                                    font-family: interregular;
                                  "
                                >
                                  {{ conf.rite.name }} , &nbsp;
                                  {{ conf.language.languageName }}
                                </span>
                              </v-flex>

                              <!-- edit mass -->
                              <v-dialog
                                v-model="editmonthlydialog"
                                max-width="800px"
                              >
                                <v-card rounded="lg">
                                  <v-card-title>
                                    <span class="editbox"
                                      >EDIT MONTHLY CONFESSION
                                    </span>
                                    <v-spacer></v-spacer>
                                  </v-card-title>

                                  <v-card-text>
                                    <v-form>
                                      <v-layout wrap justify-start px-2>
                                        <v-flex xs6 pr-2>
                                          <span class="label">Week</span>
                                          <v-select
                                            outlined
                                            dense
                                            hide-details
                                            class="pt-2 text-des"
                                            :items="numOfWeek"
                                            item-value="value"
                                            item-text="name"
                                            v-model="editingitem.numOfWeek"
                                            placeholder="Select a Day"
                                          ></v-select>
                                        </v-flex>

                                        <v-flex xs6 pr-2>
                                          <span class="label">Day</span>
                                          <v-select
                                            outlined
                                            dense
                                            hide-details
                                            class="pt-2 text-des"
                                            :items="daysOfWeek"
                                            item-value="value"
                                            item-text="name"
                                            v-model="editingitem.daysOfWeek"
                                            placeholder="Select a Day"
                                          ></v-select>
                                        </v-flex>

                                        <v-flex xs6 pr-2 pt-3>
                                          <span class="label">Start Time</span>
                                          <v-layout>
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              class="pt-2 text-des pr-2"
                                              :items="hoursArray"
                                              v-model="editingitem.startHours"
                                              placeholder="Hours"
                                            ></v-select>
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              class="pt-2 text-des pr-2"
                                              :items="minutesArray"
                                              v-model="editingitem.startMinutes"
                                              placeholder="Minutes"
                                            ></v-select>
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              class="pt-2 text-des pr-2"
                                              :items="periodArray"
                                              v-model="editingitem.startPeriod"
                                              placeholder="AM/PM"
                                            ></v-select>
                                          </v-layout>
                                        </v-flex>

                                        <v-flex xs6 pr-2 pt-3>
                                          <span class="label">End Time</span>
                                          <v-layout>
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              class="pt-2 text-des pr-2"
                                              :items="hoursArray"
                                              v-model="editingitem.endHours"
                                              placeholder="Hours"
                                            ></v-select>
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              class="pt-2 text-des pr-2"
                                              :items="minutesArray"
                                              v-model="editingitem.endMinutes"
                                              placeholder="Minutes"
                                            ></v-select>
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              class="pt-2 text-des pr-2"
                                              :items="periodArray"
                                              v-model="editingitem.endPeriod"
                                              placeholder="AM/PM"
                                            ></v-select>
                                          </v-layout>
                                        </v-flex>

                                        <v-flex xs6 pr-2 pt-3>
                                          <span class="label"> Rite </span>
                                          <v-select
                                            class="pt-2 text-des"
                                            style="font-size: 14px"
                                            outlined
                                            dense
                                            :items="riteArray"
                                            v-model="editingitem.rite"
                                            item-text="name"
                                            item-value="_id"
                                          ></v-select>
                                        </v-flex>
                                        <v-flex xs6 pr-2 pt-3>
                                          <span class="label"> Language </span>
                                          <v-select
                                            class="pt-2 text-des"
                                            style="font-size: 14px"
                                            outlined
                                            dense
                                            :items="languageArray"
                                            item-text="languageName"
                                            item-value="_id"
                                            v-model="editingitem.language"
                                          ></v-select>
                                        </v-flex>
                                      </v-layout>
                                    </v-form>
                                  </v-card-text>

                                  <v-divider></v-divider>

                                  <v-card-actions
                                    class="py-4 justify-end headline grey lighten-2"
                                  >
                                    <v-btn
                                      outlined
                                      color="grey"
                                      text
                                      @click="editmonthlydialog = false"
                                      ><span style="color: black">Cancel</span>
                                    </v-btn>
                                    <v-btn
                                      color="primary"
                                      @click="editMonthly(editingitem._id)"
                                      >Save</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </template>

                      <!-- Show this if holyMassEntries array is empty -->
                      <template v-else>
                        <v-flex
                          xs12
                          text-center
                          py-5
                          style="
                            font-family: intersemibold;
                            color: grey;
                            font-size: 13px;
                          "
                        >
                          No Confessions Available
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-card-text>
          </v-expand-transition>

          <v-layout wrap justify-start px-2>
            <!-- Add Holy Mass Form -->
            <v-card-text v-if="isAddingcon">
              <v-form v-model="formValid" ref="confessionForm">
                <!-- Content for normal Mass Form -->
                <v-layout wrap justify-center v-if="currentAddType === 'normal'">
                  
                  <!-- Left Side: Select Day -->
                  <v-flex xs12 sm2 md2 lg2 align-self-center pr-2>
                    <v-select
                      placeholder="Select day"
                      outlined
                      dense
                      :rules="[rules.required]"
                      class="text-field"
                      :items="daysOfWeek"
                      item-value="value"
                      item-text="name"
                      v-model="selectedDay"
                    ></v-select>
                  </v-flex>
          
                  <!-- Vertical Divider -->
                  <v-flex xs12 sm1 md1 lg1 d-flex align-center justify-center>
                    <v-divider vertical></v-divider>
                  </v-flex>
          
                  <!-- Right Side: Rite, Language, and Time -->
                  <v-flex xs12 sm8 md8 lg9>
                    <v-layout wrap >
                      <v-flex xs12>
                        <v-layout  align-center v-for="(entry, index) in confessions" :key="entry._id" wrap>
                          
                          <!-- Rite -->
                          <v-flex xs12 sm6 md6 lg2 pr-2>
                            <v-select
                              outlined
                              dense
                              :rules="[rules.required]"
                              :items="riteArray"
                              v-model="entry.rite"
                              placeholder="Select Rite"
                              class="text-field"
                              item-text="name"
                              item-value="_id"
                            ></v-select>
                          </v-flex>
          
                          <!-- Language -->
                          <v-flex xs12 sm6 md6 lg3 pr-2>
                            <v-select
                              outlined
                              dense
                              :rules="[rules.required]"
                              :items="languageArray"
                              item-text="languageName"
                              item-value="_id"
                              v-model="entry.language"
                              placeholder="Select Language"
                              class="text-field"
                            ></v-select>
                          </v-flex>
          
                          <!-- Start Time -->
                          <v-flex xs12 sm6 md6 lg3 pr-2>
                            <v-menu
                              v-model="entry.menu"
                              :close-on-content-click="false"
                              :nudge-bottom="40"  :nudge-left="50"
                              transition="slide-y-reverse-transition"
                              width="200"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="entry.formattedTime"
                                  outlined
                                  dense
                                  class="text-field"
                                  placeholder="Start Time"
                                  :rules="[rules.required]"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-card class="time-picker-card" width="auto">
                                <v-card-text class="time-picker-card-text">
                                  <v-layout row wrap>
                                    <v-flex xs4>
                                      <v-select
                                        v-model="entry.selectedHour"
                                        :items="hours"
                                        label="Hour"
                                        solo
                                        dense
                                        hide-details
                                        :rules="[rules.required]"
                                        class="time-picker-select"
                                        @change="updateFormattedTime(entry)"
                                      ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                      <v-select
                                        v-model="entry.selectedMinute"
                                        :items="minutes"
                                        label="Minute"
                                        solo
                                        dense
                                        hide-details
                                        :rules="[rules.required]"
                                        class="time-picker-select"
                                        @change="updateFormattedTime(entry)"
                                      ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                      <v-select
                                        v-model="entry.selectedPeriod"
                                        :items="periods"
                                        label="AM/PM"
                                        solo
                                        dense
                                        hide-details
                                        :rules="[rules.required]"
                                        class="time-picker-select"
                                        @change="updateFormattedTime(entry)"
                                      ></v-select>
                                    </v-flex>
                                  </v-layout>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </v-flex>
          
                          <!-- End Time -->
                          <v-flex xs11 sm5 md5 lg3 pr-2>
                            <v-menu
                              v-model="entry.menu2"
                              :close-on-content-click="false"
                              :nudge-bottom="40"  :nudge-left="50"
                              transition="slide-y-reverse-transition"
                              width="200"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="entry.formattedTime2"
                                  outlined
                                  dense
                                  class="text-field"
                                  placeholder="End Time"
                                  :rules="[rules.required]"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-card class="time-picker-card" width="auto">
                                <v-card-text class="time-picker-card-text">
                                  <v-layout row wrap>
                                    <v-flex xs4>
                                      <v-select
                                        v-model="entry.endHours"
                                        :items="hours"
                                        label="Hour"
                                        solo
                                        dense
                                        hide-details
                                        :rules="[rules.required]"
                                        class="time-picker-select"
                                        @change="updateFormattedTime2(entry)"
                                      ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                      <v-select
                                        v-model="entry.endMinutes"
                                        :items="minutes"
                                        label="Minute"
                                        solo
                                        dense
                                        hide-details
                                        class="time-picker-select"
                                        @change="updateFormattedTime2(entry)"
                                      ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                      <v-select
                                        v-model="entry.endPeriod"
                                        :items="periods"
                                        label="AM/PM"
                                        solo
                                        dense
                                        hide-details
                                        :rules="[rules.required]"
                                        class="time-picker-select"
                                        @change="updateFormattedTime2(entry)"
                                      ></v-select>
                                    </v-flex>
                                  </v-layout>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </v-flex>
          
                          <!-- Remove Button -->
                          <v-flex xs1 pl-md-4 pl-sm-2>
                            <v-btn
                              icon
                              @click="removeEntry(index)"
                              color="red"
                              small
                              v-if="confessions.length > 1"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
          
                      <!-- Add More Button -->
                      <v-flex xs10 text-left pt-2>
                        <v-btn outlined color="blue darken-5" @click="addEntry" small>
                          <v-icon>mdi-plus</v-icon>More
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
          
                  <!-- Add Confession Button -->
                  <v-flex xs12 text-right pb-3>
                    <v-btn
                      color="blue darken-1"
                      @click="submitForm"
                      style="font-family: interregular; font-size: 15px; color: white;"
                    >
                      Add Confession
                    </v-btn>
                  </v-flex>
                </v-layout>
          
                <!-- Content for Special Mass Form -->
                <v-layout align-center wrap justify-start pl-8 pt-0 v-if="currentAddType === 'special'">
                  <v-flex xs12>
                    <AddSpecialConfession @stepper="winStepper" @dataAdded="handleDataAdded" />
                  </v-flex>
                </v-layout>
          
                <!-- Content for Monthly Mass Form -->
                <v-layout align-center wrap justify-center pt-0 v-if="currentAddType === 'monthly'">
                  <AddMonthlyConfession @stepper="winStepper" @dataAdded="handleDataAdded" />
                </v-layout>
              </v-form>
            </v-card-text>
          </v-layout>
          
          

        </v-card>
      </v-flex>
    </v-layout>
    <!-- edit mass -->
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT CONFESSION </span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start px-2>
              <v-flex xs6 pr-2>
                <span class="label">Day</span>
                <v-select
                  outlined
                  dense
                  hide-details
                  class="pt-2 text-des"
                  :items="daysOfWeek"
                  item-value="value"
                  item-text="name"
                  v-model="editingitem.daysOfWeek"
                  placeholder="Select a Day"
                ></v-select>
              </v-flex>

              <v-flex xs6 pr-2>
                <span class="label"> Rite </span>
                <v-select
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  outlined
                  dense
                  :items="riteArray"
                  v-model="editingitem.rite"
                  item-text="name"
                  item-value="_id"
                ></v-select>
              </v-flex>
              <v-flex xs6 pr-2>
                <span class="label"> Language </span>
                <v-select
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  outlined
                  dense
                  :items="languageArray"
                  item-text="languageName"
                  item-value="_id"
                  v-model="editingitem.language"
                ></v-select>
              </v-flex>

              <v-flex xs6 pr-2>
                <span class="label">Start Time</span>
                <v-layout>
                  <v-select
                    outlined
                    dense
                    hide-details
                    class="pt-2 text-des pr-2"
                    :items="hoursArray"
                    v-model="editingitem.startHours"
                    placeholder="Hours"
                  ></v-select>
                  <v-select
                    outlined
                    dense
                    hide-details
                    class="pt-2 text-des pr-2"
                    :items="minutesArray"
                    v-model="editingitem.startMinutes"
                    placeholder="Minutes"
                  ></v-select>
                  <v-select
                    outlined
                    dense
                    hide-details
                    class="pt-2 text-des"
                    :items="periodArray"
                    v-model="editingitem.startPeriod"
                    placeholder="AM/PM"
                  ></v-select>
                </v-layout>
              </v-flex>

              <v-flex xs6 pr-2>
                <span class="label">End Time</span>
                <v-layout>
                  <v-select
                    outlined
                    dense
                    hide-details
                    class="pt-2 text-des pr-2"
                    :items="hoursArray"
                    v-model="editingitem.endHours"
                    placeholder="Hours"
                  ></v-select>
                  <v-select
                    outlined
                    dense
                    hide-details
                    class="pt-2 text-des pr-2"
                    :items="minutesArray"
                    v-model="editingitem.endMinutes"
                    placeholder="Minutes"
                  ></v-select>
                  <v-select
                    outlined
                    dense
                    hide-details
                    class="pt-2 text-des"
                    :items="periodArray"
                    v-model="editingitem.endPeriod"
                    placeholder="AM/PM"
                  ></v-select>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="editdialog = false"
            ><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="edit(editingitem._id)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this Confession?
            <br />
            This action
            <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img
                src="@/assets/images/calendar.png"
                width="70px"
                height="70px"
                class="dialog-icon"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn
            style="font-family: interBold; font-size: 13px; color: white"
            color="#cf3a45"
            @click="deletedialog = false"
            >Cancel</v-btn
          >
          <v-btn
            style="font-family: interBold; font-size: 13px; color: white"
            color="blue darken-1"
            @click="confirmDelete"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import axios from "axios";
import AddSpecialConfession from "./addSpecialConfession.vue";
import AddMonthlyConfession from "./addMonthlyConfession.vue";
export default {
  components: {
    AddSpecialConfession,
    AddMonthlyConfession,
  },
  data() {
    return {
      formValid: false,
      rules: {
        required: (value) => !!value || 'This field is required',
      },
      Pagelength: "",
      appLoading: false,
     page: 1,
     limit: 6,
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      deletedialog: false,
      dialogdate: false,
      OccasionDate: null,
      hours: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, "0")
      ),
      minutes: Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, "0")
      ),
      periods: ["AM", "PM"],
      // edit

      //selectedDay: null,
      //selectedWeek: null,

      normalconf: [],
      specialconf: [],
      monthlyConf: [],
      OccasionType: "Normal",
      menu2: false,
      menu: false,
      isAddingcon: false,
      expandedconf: false,
      currentAddType: null,

      hoursArray: Array.from({ length: 12 }, (_, i) => String(i + 1)), // ['1', '2', ..., '12']
      minutesArray: Array.from({ length: 60 }, (_, i) =>
        String(i).padStart(2, "0")
      ),
      periodArray: ["AM", "PM"],
      editingitem: {
        daysOfWeek: "",
        numOfWeek: "",
        startHours: "",
        startMinutes: "",
        startPeriod: "",

        endHours: "",
        endMinutes: "",
        endPeriod: "",
      },
      editDate: "",
      editdialog: false,
      editspecialdialog: false,
      editmonthlydialog: false,
      //time: null,
      numOfWeek: [
        { name: "First Week", value: "1" },
        { name: "Second Week", value: "2" },
        { name: "Third Week", value: "3" },
        { name: "Fourth Week", value: "4" },
        { name: "Fifth Week", value: "5" },
      ],
      selectedWeek: null,
      daysOfWeek: [
        { name: "SUN", value: "0" },
        { name: "MON", value: "1" },
        { name: "TUE", value: "2" },
        { name: "WED", value: "3" },
        { name: "THR", value: "4" },
        { name: "FRI", value: "5" },
        { name: "SAT", value: "6" },
      ],
      selectedDay: null,

      riteArray: [],
      languageArray: [],
      confessions: [
        {
          rite: "",
          language: "",
          selectedHour: "",
          selectedMinute: "",
          selectedPeriod: "",
          menu: false,

          endHours: "",
          endMinutes: "",
          endPeriod: "",
          menu2: false,

          OccasionType: "Normal",
        },
      ],
    };
  },
  watch: {
    page() {
      this.getSpecialData();
    },
    selectedDay() {
      this.getData();
    },

    selectedWeek() {
      this.getMonthlyData();
    },
    daysOfWeek(newValue) {
      console.log("Days of Week:", newValue);
    },
    "editingitem.daysOfWeek"(newValue) {
      console.log("Editing Item Day Value:", newValue);
    },

    "editingitem.numOfWeek"(newValue) {
      console.log("Editing Item Week Value:", newValue);
    },

    editingitem: {
      deep: true,
      handler() {
        if (this.editingitem && this.editingitem.occasionDate) {
          this.editDate = this.formatDate(
            new Date(this.editingitem.occasionDate)
          );
        }
      },
    },
  },
  computed: {
    nowDate() {
      const now = new Date();
      return now.toISOString().substr(0, 10);
    },
    formattedTime() {
      return `${this.selectedHour}:${this.selectedMinute} ${this.selectedPeriod}`;
    },
    formattedTime2() {
      return `${this.endHours}:${this.endMinutes} ${this.endPeriod}`;
    },
    // formattedDate() {
    //   // Only format and return the date if one is selected
    //   return this.OccasionDate
    //     ? new Date(this.OccasionDate).toLocaleDateString()
    //     : "";
    // },
    formattedDate: {
      get() {
        // Format date as 'YYYY-MM-DD'
        return this.editDate ? this.formatDate(this.editDate) : "";
      },
      set(newValue) {
        // Convert string to Date object and update
        this.editDate = newValue;
        this.editingitem.occasionDate = this.parseDate(newValue);
      },
    },
  },
  mounted() {
    // console.log("numOfWeek on mount:", this.numOfWeek);
    // console.log("day on mount:", this.daysOfWeek);
    this.getLanguage();
    this.getRite();
    this.getData();
    this.setDefaultDay();
    this.setDefaultWeek();
    this.getSpecialData();
    this.getMonthlyData();
  },
  methods: {
    formatDate1(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB").replace(/\//g, "-");
    },

    // winStepper(item) {
    //   if (item.ref == 'addSpecial') {
    //     this.getSpecialData = item.getSpecialData
    //     this.getSpecialData();
    //     console.log("haiii",item)
    //   }
    //   if (item.ref == 'addMonthly') {
    //     this.getMonthlyData = item.getMonthlyData
    //   }
    // },
    // date
    // updateDate(OccasionDate) {
    //   this.OccasionDate = OccasionDate;
    //   this.dialogdate = false; // Close the dialog after selecting the date
    // },

    winStepper(item) {
      if (item.ref === "addSpecial") {
        this.expandedconf=true
        
        // this.getSpecialData = item.getSpecialData;
        this.getSpecialData();
      }
      if (item.ref === "addMonthly") {
        this.expandedconf=true
        // this.getSpecialData = item.getSpecialData;
        this.getMonthlyData();
      }
    },
    handleDataAdded(item) {
      console.log("Received dataAdded event", item);
      if (item.getSpecialData) {
        this.expandedconf=true
        //console.log("Calling getSpecialData()");
        this.getSpecialData();
      }
      if (item.getMonthlyData) {
        // console.log("Calling getMonthlyData()");
        this.expandedconf=true
        this.getMonthlyData();
      }
    },

    addEntry() {
      this.confessions.push({
        rite: "",
        language: "",
        selectedHour: "",
        selectedMinute: "",
        selectedPeriod: "",
        menu: false,
        formattedTime: "",

        endHours: "",
        endMinutes: "",
        endPeriod: "",
        menu2: false,
        formattedTime2: "",
        OccasionType: "Normal",
      });
    },
    removeEntry(index) {
      this.confessions.splice(index, 1);
    },
    // convertTo24HourFormat(hour, minute, period) {
    //   let convertedHour = parseInt(hour, 10);
    //   if (period === "PM" && convertedHour !== 12) {
    //     convertedHour += 12;
    //   } else if (period === "AM" && convertedHour === 12) {
    //     convertedHour = 0;
    //   }
    //   return `${convertedHour.toString().padStart(2, "0")}:${minute}`;
    // },

    convertTo24HourFormat(hour, minute, period) {
      let convertedHour = parseInt(hour, 10);

      // Convert 12 AM to 00 (midnight) in 24-hour format
      if (period === "AM" && convertedHour === 12) {
        convertedHour = 0;
      }
      // Convert 12-hour PM time to 24-hour format (except 12 PM)
      else if (period === "PM" && convertedHour !== 12) {
        convertedHour += 12; // Convert to 24-hour format for PM times
      }

      return `${convertedHour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    },

    // updateFormattedTime(entry) {
    //   const hour12 = this.convertTo24HourFormat(
    //     entry.selectedHour,
    //     entry.selectedMinute,
    //     entry.selectedPeriod
    //   );
    //   entry.formattedTime = `${hour12} ${entry.selectedPeriod}`;
    //   console.log(entry.formattedTime);
    // },
    updateFormattedTime(entry) {
      // This sets the formatted time to display in 12-hour format with AM/PM
      entry.formattedTime = `${entry.selectedHour}:${entry.selectedMinute} ${entry.selectedPeriod}`;
      console.log(entry.formattedTime);
    },

    updateFormattedTime2(entry) {
      // This sets the formatted time to display in 12-hour format with AM/PM
      entry.formattedTime2 = `${entry.endHours}:${entry.endMinutes} ${entry.endPeriod}`;
      console.log(entry.formattedTime2);
    },

    // updateFormattedTime2(entry) {
    //   const hour12 = this.convertTo24HourFormat(
    //     entry.endHours,
    //     entry.endMinutes,
    //     entry.endPeriod
    //   );
    //   entry.formattedTime2 = `${hour12} ${entry.endPeriod}`;
    //   console.log(entry.formattedTime2);
    // },

    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/normal/confessions",

        method: "GET",
        params: {
          id: this.$route.query.id,
          dayOfWeek: this.selectedDay,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: {
        //   //  count: this.count,
        //   // page: this.currentPage,
        //   rite: this.rite,
        //   language: this.language,
        //   keyword: this.keyword,
        //   telecastType: this.selectedServicesNames,
        //   fromDate: this.fromDate,
        //   toDate:this.toDate,

        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.normalconf = response.data.data;

          // this.expandedconf = false;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getSpecialData() {
      this.appLoading = true;
      axios({
        url: "/get/special/confessions",
        method: "GET",
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: this.limit,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.specialconf = response.data.data;
          this.Pagelength = Math.ceil(response.data.totalLength / this.limit);
          //this.expandedconf = true;
          this.isAddingcon = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // getSpecialData() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/get/special/holymasses",

    //     method: "GET",
    //     params: {
    //       id: this.$route.query.id,
    //       limit: this.limit,
    //       page: this.currentPage,
    //       //dayOfWeek: this.selectedDay,
    //     },
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },

    //     // params: {
    //     //   //  count: this.count,
    //     //   // page: this.currentPage,
    //     //   rite: this.rite,
    //     //   language: this.language,
    //     //   keyword: this.keyword,
    //     //   telecastType: this.selectedServicesNames,
    //     //   fromDate: this.fromDate,
    //     //   toDate:this.toDate,

    //     // },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.specialMassEntries = response.data.data;
    //       // this.pages = response.data.pages;
    //       // this.totalData = response.data.count;
    //       this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       // console.log(this.numOfWeek.map(day => day.value)); // Print only values to check uniqueness

    //       // console.log(this.daysOfWeek.map(day => day.value)); // Print only values to check uniqueness

    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    getMonthlyData() {
      this.appLoading = true;
      axios({
        url: "/get/monthlyspecial/confessions",

        method: "GET",
        params: {
          id: this.$route.query.id,
          weekOfMonth: this.selectedWeek,
          // limit: this.limit,
          // page: this.currentPage,
          //dayOfWeek: this.selectedDay,
        },
        headers: {
          token: localStorage.getItem("token"),
        },

        // params: {
        //   //  count: this.count,
        //   // page: this.currentPage,
        //   rite: this.rite,
        //   language: this.language,
        //   keyword: this.keyword,
        //   telecastType: this.selectedServicesNames,
        //   fromDate: this.fromDate,
        //   toDate:this.toDate,

        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.monthlyConf = response.data.data;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          // this.expandedconf = true;
          this.isAddingcon = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //  convertUTCToLocal (utcDateString) {
    //     const utcDate = new Date(utcDateString);

    //     // Convert to local time using toLocaleString
    //     const localTimeString = utcDate.toLocaleString('en-US', {
    //       hour: 'numeric',
    //       minute: 'numeric',
    //       hour12: true
    //     });

    //     return localTimeString;
    //   },
    // Example conversion in your method

    //     editSlider(mass) {
    //     this.editingitem = { ...mass };  // Ensure editingitem is correctly populated
    //     this.editingitem.daysOfWeek = String(this.editingitem.daysOfWeek); // Convert to string if needed
    //     this.editdialog = true;
    //     console.log('Editing Item:', this.editingitem);
    // },
    // edit() {
    //     this.appLoading = true;

    //     // Prepare the user object for API call
    //     const user = {
    //         id: this.editingitem._id,
    //         parish: this.editingitem.parish,
    //         dayOfWeek: this.editingitem.daysOfWeek,
    //         rite: this.editingitem.rite,
    //         language: this.editingitem.language,
    //         startTime: this.editingitem.startTime // Handle conversion if needed
    //     };

    //     // Make the API call
    //     axios({
    //         url: "/edit/holymass",
    //         method: "POST",
    //         data: user,
    //         headers: {
    //             token: localStorage.getItem("token"),
    //         },
    //     })
    //     .then((response) => {
    //         this.appLoading = false;
    //         if (response.data.status) {
    //             this.editdialog = false;
    //             this.msg = "Edited Successfully";
    //             this.showsnackbar = true;
    //             this.getData();
    //         } else {
    //             this.msg = response.data.msg;
    //             this.showsnackbar = true;
    //         }
    //     })
    //     .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //     });
    // },

    updateDate(date) {
      this.menu = false; // Close the menu after selecting the date
      this.editDate = date;
      this.editingitem.occasionDate = this.formatDate(date);
    },
    formatDate(date) {
      if (!date) return "";
      // Ensure date is formatted to 'YYYY-MM-DD'
      return date instanceof Date ? date.toISOString().slice(0, 10) : date;
    },
    parseDate(dateString) {
      // Convert 'YYYY-MM-DD' format to a Date object
      return new Date(dateString).toISOString();
    },
    editSlider(conf) {
      this.editingitem = { ...conf };

      // Parse the startTime and set it in the format needed
      const { hours, minutes, period } = this.parseTime(
        this.editingitem.startTime
        // this.editingitem.endTime
      );
      const { hours1, minutes1, period1 } = this.parseTime1(
        this.editingitem.endTime
        // this.editingitem.endTime
      );
      this.editingitem.daysOfWeek = String(this.editingitem.daysOfWeek); // Convert to string if needed
      this.editingitem.startHours = String(hours); // Convert to string if hoursArray contains strings
      this.editingitem.startMinutes = String(minutes).padStart(2, "0");
      this.editingitem.startPeriod = period;

      this.editingitem.endHours = String(hours1); // Convert to string if hoursArray contains strings
      this.editingitem.endMinutes = String(minutes1).padStart(2, "0");
      this.editingitem.endPeriod = period1;

      this.editdialog = true;
      console.log("Editing Item:", this.editingitem);
    },

    parseTime(isoString) {
      // Extract the time part from the string without creating a Date object
      const timePart = isoString.split("T")[1].split("Z")[0];
      let [hours, minutes] = timePart.split(":");

      hours = parseInt(hours);
      const period = hours >= 12 ? "PM" : "AM";

      // Convert 24-hour format to 12-hour format
      hours = hours % 12 || 12;

      return { hours, minutes: parseInt(minutes), period };
    },
    parseTime1(isoString) {
      // Extract the time part from the string without creating a Date object
      const timePart = isoString.split("T")[1].split("Z")[0];
      let [hours1, minutes1] = timePart.split(":");

      hours1 = parseInt(hours1);
      const period1 = hours1 >= 12 ? "PM" : "AM";

      // Convert 24-hour format to 12-hour format
      hours1 = hours1 % 12 || 12;

      return { hours1, minutes1: parseInt(minutes1), period1 };
    },
    edit() {
      this.appLoading = true;

      const hours = parseInt(this.editingitem.startHours, 10);
      const minutes = parseInt(this.editingitem.startMinutes, 10);
      const period = this.editingitem.startPeriod;

      const hours1 = parseInt(this.editingitem.endHours, 10);
      const minutes1 = parseInt(this.editingitem.endMinutes, 10);
      const period1 = this.editingitem.endPeriod;

      const startTime24 =
        period === "PM" && hours < 12
          ? hours + 12
          : period === "AM" && hours === 12
          ? 0
          : hours;

      const endTime24 =
        period1 === "PM" && hours1 < 12
          ? hours1 + 12
          : period1 === "AM" && hours1 === 12
          ? 0
          : hours1;
      const formattedTime = `${String(startTime24).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`;

      const formattedTime2 = `${String(endTime24).padStart(2, "0")}:${String(
        minutes1
      ).padStart(2, "0")}:00`;

      const user = {
        id: this.editingitem._id,
        //parish: this.editingitem.parish,

        dayOfWeek: this.editingitem.daysOfWeek,
        // rite: this.editingitem.rite._id,
        // language: this.editingitem.language._id,
        rite: this.editingitem.rite,
        language: this.editingitem.language,
        startTime: `2000-11-07T${formattedTime}.000Z`, // Adjust date as necessary
        endTime: `2000-11-07T${formattedTime2}.000Z`, // Adjust date as necessary
        OccasionType: "Normal",
      };

      axios({
        url: "/edit/confession",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // edit special

    editSliderSpecial(conf) {
      this.editingitem = { ...conf };

      // Parse the startTime and set it in the format needed
      const { hours, minutes, period } = this.parseTime(
        this.editingitem.startTime
      );
      const { hours1, minutes1, period1 } = this.parseTime1(
        this.editingitem.endTime
        // this.editingitem.endTime
      );
      //this.editingitem.daysOfWeek = String(this.editingitem.daysOfWeek); // Convert to string if needed
      this.editingitem.startHours = String(hours); // Convert to string if hoursArray contains strings
      this.editingitem.startMinutes = String(minutes).padStart(2, "0");
      this.editingitem.startPeriod = period;
      this.editingitem.endHours = String(hours1); // Convert to string if hoursArray contains strings
      this.editingitem.endMinutes = String(minutes1).padStart(2, "0");
      this.editingitem.endPeriod = period1;

      this.editspecialdialog = true;
      console.log("Editing Item:", this.editingitem);
    },

    editSpecial() {
      this.appLoading = true;

      const hours = parseInt(this.editingitem.startHours, 10);
      const minutes = parseInt(this.editingitem.startMinutes, 10);
      const period = this.editingitem.startPeriod;
      const hours1 = parseInt(this.editingitem.endHours, 10);
      const minutes1 = parseInt(this.editingitem.endMinutes, 10);
      const period1 = this.editingitem.endPeriod;

      const startTime24 =
        period === "PM" && hours < 12
          ? hours + 12
          : period === "AM" && hours === 12
          ? 0
          : hours;

      const endTime24 =
        period1 === "PM" && hours1 < 12
          ? hours1 + 12
          : period1 === "AM" && hours1 === 12
          ? 0
          : hours1;

      const formattedTime = `${String(startTime24).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`;
      const formattedTime2 = `${String(endTime24).padStart(2, "0")}:${String(
        minutes1
      ).padStart(2, "0")}:00`;

      const user = {
        id: this.editingitem._id,
        //parish: this.editingitem.parish,

        // dayOfWeek: this.editingitem.daysOfWeek,
        rite: this.editingitem.rite,
        language: this.editingitem.language,
        startTime: `2000-11-07T${formattedTime}.000Z`, // Adjust date as necessary\
        endTime: `2000-11-07T${formattedTime2}.000Z`, // Adjust date as necessary
        OccasionType: "Special",
        OccasionDate: this.editingitem.occasionDate,
      };

      axios({
        url: "/edit/confession",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editspecialdialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getSpecialData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editSliderMonthly(conf) {
      console.log("Mass Object:", conf); // Log the entire mass object to inspect its structure

      // Check if these properties exist in the mass object
      if (conf.recurringMonthly) {
        this.editingitem = { ...conf };

        // Parse the startTime and set it in the format needed
        const { hours, minutes, period } = this.parseTime(
          this.editingitem.startTime
        );

        const { hours1, minutes1, period1 } = this.parseTime1(
          this.editingitem.endTime
        );
        this.editingitem.daysOfWeek = String(conf.recurringMonthly.dayOfWeek); // Correctly access dayOfWeek
        this.editingitem.numOfWeek = String(conf.recurringMonthly.weekOfMonth); // Correctly access weekOfMonth
        this.editingitem.startHours = String(hours); // Convert to string if hoursArray contains strings
        this.editingitem.startMinutes = String(minutes).padStart(2, "0");
        this.editingitem.startPeriod = period;

        this.editingitem.endHours = String(hours1); // Convert to string if hoursArray contains strings
        this.editingitem.endMinutes = String(minutes1).padStart(2, "0");
        this.editingitem.endPeriod = period1;

        this.editmonthlydialog = true;
        console.log("Editing Item:", this.editingitem);
      } else {
        console.error("recurringMonthly is not defined in the mass object");
      }
    },

    editMonthly() {
      this.appLoading = true;

      const hours = parseInt(this.editingitem.startHours, 10);
      const minutes = parseInt(this.editingitem.startMinutes, 10);
      const period = this.editingitem.startPeriod;

      const hours1 = parseInt(this.editingitem.endHours, 10);
      const minutes1 = parseInt(this.editingitem.endMinutes, 10);
      const period1 = this.editingitem.endPeriod;

      const startTime24 =
        period === "PM" && hours < 12
          ? hours + 12
          : period === "AM" && hours === 12
          ? 0
          : hours;

      const endTime24 =
        period1 === "PM" && hours1 < 12
          ? hours1 + 12
          : period1 === "AM" && hours1 === 12
          ? 0
          : hours1;
      const formattedTime = `${String(startTime24).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`;
      const formattedTime2 = `${String(endTime24).padStart(2, "0")}:${String(
        minutes1
      ).padStart(2, "0")}:00`;

      const user = {
        id: this.editingitem._id,
        //parish: this.editingitem.parish,

        //dayOfWeek: this.editingitem.daysOfWeek,
        recurringMonthly: {
          weekOfMonth: this.editingitem.numOfWeek, // Use the value from editingitem
          dayOfWeek: this.editingitem.daysOfWeek, // Use the value from editingitem
        },
        // rite: this.editingitem.rite._id,
        // language: this.editingitem.language._id,
        rite: this.editingitem.rite,
        language: this.editingitem.language,
        startTime: `2000-11-07T${formattedTime}.000Z`, // Adjust date as necessary
        endTime: `2000-11-07T${formattedTime2}.000Z`, // Adjust date as necessary
        OccasionType: "MonthlySpecial",
      };

      axios({
        url: "/edit/confession",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editmonthlydialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getMonthlyData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submitForm() {
      if (this.$refs.confessionForm.validate()) {
        this.itemadd(); // Your existing method for adding Holy Mass
      } else {
        this.msg = "Please fill out all required fields";
        this.showSnackBar = true;
      }
    },
    itemadd() {
      const data = {
        // if(this.holyrite || this.ghh){
        //   dayOfWeek:this.holyrite
        // }
        // assuming you have parishId defined in your data
        parish: this.$route.query.id,
        dayOfWeek: this.selectedDay,

        confessions: this.confessions.map((entry) => ({
          // OccasionType: entry.OccasionType,
          OccasionType: "Normal", 
          rite: entry.rite,
          language: entry.language,
          // startTime: entry.formattedTime, // Combine time
          startTime: this.convertTo24HourFormat(
            entry.selectedHour,
            entry.selectedMinute,
            entry.selectedPeriod
          ),
          endTime: this.convertTo24HourFormat(
            entry.endHours,
            entry.endMinutes,
            entry.endPeriod
          ),
          // Include OccasionType if applicable
        })),
      };

      axios({
        url: "/add/multiple/confession",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.isAddingcon = !this.isAddingcon;
            if (!this.isAddingcon) {
              this.currentAddType = null; // Reset the currentAddType when exiting add mode
            }
            this.expandedconf = true;
            // Clear form fields or reset state
           // this.selectedDay = null;
            this.getData();
            //window.location.reload();
            this.confessions = [
              {
                rite: "",
                language: "",
                selectedHour: "12",
                selectedMinute: "00",
                selectedPeriod: "AM",
                menu: false,
                OccasionType: "Normal",
                endHours: "12",
                endMinutes: "00",
                endPeriod: "AM",
                menu2: false,
              },
            ];
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // formatTimes(startTime) {
    //     return `${this.convertUTCToLocal(startTime)} `;
    //   },
    formatTime(dateTime) {
      // Extract the time part from the string
      const timePart = dateTime.split("T")[1].split("Z")[0];

      // Split time into hours and minutes
      let [hours, minutes] = timePart.split(":");

      // Convert hours to a number
      hours = parseInt(hours);

      // Determine AM/PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert 24-hour time to 12-hour time
      hours = hours % 12;
      hours = hours ? hours : 12; // Adjust 0 to 12

      // Combine hours, minutes, and AM/PM
      const formattedTime = `${hours}:${minutes} ${ampm}`;

      return formattedTime;
    },

    toggleExpand() {
      this.expandedconf = !this.expandedconf;
    },
    toggleAddMode() {
      this.isAddingcon = !this.isAddingcon;
      if (!this.isAddingcon) {
        this.currentAddType = null; // Reset the currentAddType when exiting add mode
        this.expandedconf = true;
      }
    },
    startAdd(type) {
      this.currentAddType = type;
      this.isAddingcon = true;
      this.expandedconf = false; // Collapse details view when adding
    },
    setDefaultDay() {
      // Find the first day with holyMassEntries
      const daysWithMass = this.normalconf.map((conf) => conf.dayOfWeek);
      const defaultDay = this.daysOfWeek.find((day) =>
        daysWithMass.includes(day.value)
      );

      // Set the first available day with holyMassEntries or default to the first day in the list
      this.selectedDay = defaultDay
        ? defaultDay.value
        : this.daysOfWeek[0].value;
    },
    handleDayClick(day) {
      this.selectedDay = day;
      // Logic for filtering holyMassEntries based on the selected day
    },

    setDefaultWeek() {
      // Find the first day with holyMassEntries
      const daysWithMass = this.normalconf.map((conf) => conf.weekOfMonth);
      const defaultDay = this.numOfWeek.find((day) =>
        daysWithMass.includes(day.value)
      );

      // Set the first available day with holyMassEntries or default to the first day in the list
      this.selectedWeek = defaultDay
        ? defaultDay.value
        : this.numOfWeek[0].value;
    },
    handleWeekClick(day) {
      this.selectedWeek = day;
      // Logic for filtering holyMassEntries based on the selected day
    },
    getDayName(dayValue) {
      const daysOfWeek = [
        { name: "Sunday", value: "0" },
        { name: "Monday", value: "1" },
        { name: "Tuesday", value: "2" },
        { name: "Wednesday ", value: "3" },
        { name: "Thursday", value: "4" },
        { name: "Friday", value: "5" },
        { name: "Saturday", value: "6" },
      ];
      const day = daysOfWeek.find((d) => d.value === dayValue.toString());
      return day ? day.name : "";
    },
    openDeleteDialog(conf) {
      this.itemToDelete = conf;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },

    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/delete/confession",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
            this.getSpecialData();
            this.getMonthlyData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // saveMass() {
    //   if (this.newMass.title) {
    //     this.holymass.push({ title: this.newMass.title });
    //     this.newMass.title = ''; // Clear the form
    //     this.isAddingcon = false; // Exit add mode
    //   }
    // }
  },
};
</script>

<style scoped>
.time-picker-card {
  max-width: 280px;
  /* Adjust card width */
}

.time-picker-card-text {
  padding: 8px;
  /* Adjust padding inside the card */
}

.time-picker-select {
  width: 100%;
  /* Ensure dropdowns fill column width */
}

.time-picker-card-actions {
  padding: 8px;
  /* Adjust padding in the actions area */
}

.v-menu__content {
  z-index: 1000;
  /* Ensure the menu appears on top */
}

.v-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.selected-btn {
  background-color: #37a0f4 !important;
  /* Change to desired background color */
  color: #ffffff;
  /* Change to desired text color */
}

.card-content {
  display: flex;
  align-items: center;
  width: 100%;
}


</style>